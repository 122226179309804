/* You can add global styles to this file, and also import other style files */

@import "src/styles/fd-theme.scss";
@import "src/styles/variables.scss";

@font-face {
  font-family: 'UniversForFiserv';
  src: url('~src/assets/fonts/univers-for-fiserv/UniversForFiserv45-Lt.ttf');
}

@font-face {
  font-family: 'UniversForFiserv55';
  src: url('~src/assets/fonts/univers-for-fiserv/UniversForFiserv55.ttf');
}

* {
  font-family: "UniversForFiserv";
}

html,
body {
	height: 100%;
	width: 100%;
}
body {
	margin: 0;
}

.mat-sort-header-button {
	margin-left: 10px;
  }

.container {
	background-color: $fd-background-container;
}

h1, h2, h3, h4, h5 {
		color: $fd-dark-blue;
}

h3 {
	font-size: 25px;
	font-weight: 700;
	margin: 0;
	padding: 0.5em 0;
	text-align: left;
}
//FirstData styles

.fd-subtitle {
	color: $fd-indigo-blue;
	text-align: center;
}

.fd-label {
	&__container {
		width: 100%;
		margin-top: 10px;

		& .mat-form-field-wrapper .mat-form-field-underline {
			background-color: $fd-light-gray;
		}
	}
}

.fd-button {
	width: 160px;
	padding: 7px 10px;
	border-radius: 30px;
	margin-bottom: 10px;
	font-weight: bold;
	margin: 10px auto;
	cursor: pointer;
	display: block;
	outline: none;
	background-color: $fd-background-container;
	border: 1px solid $fd-indigo-blue;
	color: $fd-indigo-blue;
  }

//Custom Angular Material styles

.mat-form-field-disabled.mat-form-field-appearance-legacy .mat-form-field-label {
	color: rgba($fd-dark-gray, 0.35);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background-color: $fd-indigo-blue !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
	background-color: $fd-indigo-blue !important;
}
.fd-input__container.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
	background-color: rgba($fd-dark-gray, 0.35);
	background-image: none;
}

.mat-form-field .mat-form-field-required-marker,
.mat-form-field.mat-focused .mat-form-field-required-marker {
	color: $fd-indigo-blue;
}

.mat-form-field .mat-select-arrow {
	width: 0;
	height: 0;
	position: relative;
	margin: 0 auto;
	border: none;
}

.mat-form-field .mat-select-arrow {
	height: 4px;
	width: 34px;
}

.mat-form-field .mat-select-arrow:before,
.mat-form-field .mat-select-arrow:after {
	content: "";
	position: absolute;
	width: 9px;
	border: 2px solid $fd-indigo-blue;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
}

.mat-form-field .mat-select-arrow:before {
	transform: rotateZ(45deg);
	-webkit-transform: rotateZ(45deg);
	left: 15px;
}
.mat-form-field .mat-select-arrow:after {
	transform: rotateZ(-45deg);
	-webkit-transform: rotateZ(-45deg);
	left: 22px;
}

.mat-radio-button .mat-radio-outer-circle {
	border-width: 1px;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
	background-color: $fd-indigo-blue;
  }

  .mat-radio-button .mat-radio-inner-circle,
  .mat-radio-button .mat-radio-outer-circle,
  .mat-radio-button .mat-radio-container {
	border-width: 1px;
	height: 30px;
	width: 30px;
  }

  .mat-radio-button .mat-radio-label-content {
	font-size: 18px;
	color: $fd-dark-gray;
  }

.fd-spinner .overlay {
	position: fixed !important;
}

// Styles modal
.fd-modal {
	&__container {
		text-align: center;
		padding: 10px 45px;
	}

	&__warning-icon {
		color: $fd-yellow;
	}

	&__title {
		text-transform: uppercase;
		color: $fd-dark-blue;
		font-weight: 700;
	}
	&__close-button {
		margin: auto;
	}

	&__checkbox {
		.mat-checkbox-inner-container {
			height: 24px;
			width: 24px;
		}
		.mat-checkbox-frame {
			border-width: 1px;
			border-style: solid;
			border-radius: 4px;
		}
		&.mat-checkbox-checked.mat-accent .mat-checkbox-background {
			background-color: $fd-indigo-blue;
		}
	}
}

.fd-option-picker {
	&__container .mat-select-placeholder {
		color: $fd-dark-gray;
	}
}

.mat-slide-toggle-bar {
	background-color: $fd-indigo-blue;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
	background-color: $fd-indigo-blue !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
	background-color: $fd-light-gray !important;
}


.mat-elevation-z {
	box-shadow: 0 -4px 4px -4px rgba(0, 0, 0, 0.14);
}

::-webkit-scrollbar {
	margin-left: 0.5%;
	float: left;
	height: 0.5%;
	width: 0.4em;
	background: #f5f5f5;
	overflow-y: scroll;
	margin-bottom: 25px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: $fd-light-blue;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: $fd-indigo-blue;
}

.value-edit-table {
	&__form:nth-of-type(odd) {
		background: #fff;
	}
	&__form:nth-of-type(even) {
		background: #f2f2f2;
	}

	&__cell {
		.mat-form-field-wrapper {
			padding-left: 10px !important;
		}
	}
}

.hidden {
	display: none;
}

.mat-select .ng-star-inserted
{
    display: inline-block;
    width: 150%;
    outline: 0;
}

span.mat-slide-toggle-content {
  font-family: "UniversForFiserv";
}
